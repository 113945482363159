export function usePayments() {
  const toCurrency = (rate: number, amount: number) => {
    const value = (amount * rate).toFixed(2);
    return Number(Number.isInteger(value) ? value.split(".")[0] : value);
  };

  /**
   * Метод для рассчёта итоговой суммы пополнения с учётом курса.
   * @param rate - курс валюты к Буллкоину/Рублю. К примеру 1 Тенге = 0.2 Рубля. 0.2 это rate. Приходит с бэка.
   * @param amount - сумма пополнения.
   * @returns
   */
  const fromCurrency = (
    rate: number,
    amount: number,
    decimalPoint: number = 2,
  ) => {
    return Number((amount / rate).toFixed(decimalPoint));
  };

  return {
    toCurrency,
    fromCurrency,
  };
}
