import type { PlatformName } from "~/types";
import type {
  PaymentGroup,
  PaymentMethod,
  CountryDetail,
  Country,
} from "~/api_gen";

export const usePaymentsStore = defineStore("paymentsStore", () => {
  const { t } = useI18n();
  const { fromCurrency } = usePayments();
  const notify = useNotify();
  const api = useApi();
  // const domain = useRequestURL().hostname;
  const platform = usePlatform();

  /**
   * Текущий выбранный метод оплаты.
   */
  const paymentMethod = ref<PaymentMethod | null>(null);

  /**
   * В значение записываем текущую локаль. Это нужно для проверки
   * необходимости обновления данных страницы платежей при смене локали
   * в функции initTopupPage
   */
  const currentLocale = ref("");

  /**
   * Список доступных платежных методов.
   */
  const paymentMethods = ref<PaymentMethod[]>([]);

  /**
   * Список платежных методов объединенных по группам.
   */
  const paymentMethodsByGroups = ref<PaymentGroup[]>([]);

  /**
   * Список методов отфильтрованные по группе, например,
   * "Карты", "Телефон", "Крипта" и т.д.
   */
  const paymentMethodsFiltered = ref<PaymentGroup[]>([]);

  /**
   * Список с наименованиями платежных груп.
   */
  const paymentGroupNames = computed(() =>
    paymentMethodsByGroups.value.map(
      (paymentMethodsGroup) => paymentMethodsGroup.shortName,
    ),
  );

  /**
   * Список элементов передаваяемых в селект выбора платежной группы.
   */
  const paymentSelectItems = computed(() => {
    return paymentGroupNames.value.map((item) => {
      return {
        title: item,
        value: item,
      };
    });
  });

  /**
   * Наименование выбранной платежной группы ("Карты", "Телефон", "Крипта" и т.д.).
   */
  const selectedPaymentsGroup = ref("");

  /**
   * Объект с данными по текущей выбранной стране.
   */
  const activeCountry = ref<CountryDetail>();

  /**
   * Список с данными по всем странам.
   */
  const allCountries = ref<Country[]>([]);

  /**
   * Валютный код текущей выбранной страны ("RUB", "USD" и т.д.).
   */
  const countryCurrency = ref("");

  /**
   * Наименование текущей выбранной страны.
   */
  const selectedCountryName = ref("");

  /**
   * Код флага выбранной страны ("ru", "uz" и т.д.).
   */
  const selectedCountryFlag = ref("");

  /**
   * Текущий выбранный Валютный код ("RUB", "USD" и т.д.).
   */
  const selectedCurrency = ref("");

  /**
   * Наименование текущей выбранной платформы.
   */
  const withdrawalGame = ref<PlatformName>(
    platform.title === "BGMI" ? "bgmi" : platform.code,
  );

  /**
   * Индикатор того, что производится загрузка данных по платежному методу.
   */
  const isPaymentMethodLoading = ref(false);

  /**
   * Индикатор того, что сменили страну в платёжном методе и надо менять выбранный метод
   * на базовый "Все"
   */
  const changeMethodToBase = ref(false);

  /**
   * Курс валюты выбранной страны к булкоину.
   */
  const currencyRate = ref(1);

  /**
   * Недостающая сумма буллкоинов до открытия кейсов.
   */
  const fundsLackBullcoins = ref(0);

  /**
   * Данные формы пополнения.
   */
  const topUpFormData = reactive({
    phone: "",
    email: "",
    voucherCode: "",
    sum: fromCurrency(currencyRate.value, useCoreStore().topUpInitialSum),
  });

  /**
   * Список доступных валют.
   */
  const availableCurrencies = computed<string[]>(() => {
    const methods = paymentMethodsByGroups.value.flatMap(
      (item) => item.methods,
    );
    return [...new Set(methods.flatMap((item) => item.currencies))];
  });

  /**
   * Инициализирует страницу платежей необходимымы данными во время ее рендеринга:
   * совершает запрос на получение списка доступных стран и плаежных методов
   * выбранной страны, устанавливает первоначально выбранный платежный метод и т.д.
   */
  async function initTopupPage() {
    const { locale } = useI18n();

    // При заходе на страницу пополнения проверяем соответствует ли текущая локаль
    // значению currentLocale и есть ли данные.
    // Если да, завершаем код.
    // Если нет - перезаписываем значение currentLocale на локаль текущую и запрашиваем данные
    if (
      locale.value === currentLocale.value &&
      paymentMethodsByGroups.value.length
    ) {
      return;
    }
    currentLocale.value = locale.value;
    await getPaymentMethodsByActiveCountry();
    await getAllCountries();
    addVoucherToMethodsList();
    paymentMethodsFiltered.value = paymentMethodsByGroups.value;

    if (paymentMethodsFiltered.value.length && !useMobileCheck()) {
      selectPaymentMethod(paymentMethodsFiltered.value[0].methods[0]);
    }
  }

  /**
   * Получает список платежных методов текущей выбранной страны.
   */
  async function getPaymentMethodsByActiveCountry() {
    isPaymentMethodLoading.value = true;
    try {
      activeCountry.value = await api.payments.countriesActiveRetrieve();
      paymentMethodsByGroups.value = activeCountry.value.methodGroups;
      paymentMethods.value = paymentMethodsByGroups.value.flatMap(
        (group) => group.methods,
      );
      selectedCountryName.value = activeCountry.value.name;
      selectedCountryFlag.value = activeCountry.value.flag;
      countryCurrency.value = activeCountry.value.currency;
      currencyRate.value = parseFloat(activeCountry.value.rate);
    } catch (e: any) {
      notify({
        type: "error",
        text: t("top_up_balance.notifications.payment_list_error"),
      });
    }
    isPaymentMethodLoading.value = false;
  }

  /**
   * Получает список всех доступных стран.
   */
  async function getAllCountries(): Promise<void> {
    try {
      allCountries.value = await api.payments.countriesList();
    } catch (e: any) {
      notify({
        type: "error",
        text: t("top_up_balance.notifications.countries_list_error"),
      });
    }
  }

  /**
   * Возвращает список методов отфильтрованный по группе.
   *
   * @param groupName - наименование группы методов ("Карты", "Телефон", "Крипта" и т.д.).
   */
  function filterPaymentMethodsByGroup(groupName: string) {
    return paymentMethodsByGroups.value.filter(
      (method) => method.shortName === groupName,
    );
  }

  /**
   * Выбирает страну, получая и отображая список ее платежных методов.
   */
  async function selectCountry(item: Country) {
    await postCountyId(item.id);
    addVoucherToMethodsList();
    selectPaymentGroup("");
    selectedCountryName.value = item.name;
    paymentMethodsFiltered.value = paymentMethodsByGroups.value;
    if (!useMobileCheck() || paymentMethod.value) {
      selectPaymentMethod(paymentMethodsFiltered.value[0].methods[0]);
    }
  }

  /**
   * Отображает список платежных методов принадлежащих указанной группе.
   * Если группа равна пустой строке, отображает все платежные методы страны.
   *
   * @param name - наименование группы платежных методов ("Карты", "Телефон", "Крипта" и т.д.).
   */
  function selectPaymentGroup(groupName: string) {
    changeMethodToBase.value = false;
    if (groupName) {
      paymentMethodsFiltered.value = filterPaymentMethodsByGroup(groupName);
    } else {
      changeMethodToBase.value = true;
      paymentMethodsFiltered.value = paymentMethodsByGroups.value;
    }
    selectedPaymentsGroup.value = groupName;
  }

  /**
   * Добавляет метод ваучеров к отображаемым платежным методам.
   */
  function addVoucherToMethodsList() {
    const paymentMethodsSource = ref<PaymentGroup[]>([]);
    paymentMethodsSource.value = JSON.parse(
      JSON.stringify(paymentMethodsByGroups.value),
    );
    for (const method of paymentMethodsSource.value) {
      if (
        method.shortName.toLowerCase() === "карты" ||
        method.shortName.toLowerCase() === "cards"
      )
        break;
    }
    const newObj = paymentMethodsSource.value;
    paymentMethodsByGroups.value = newObj;
  }

  /**
   * Получает платежные методы указанной страны.
   *
   * @param id - id выбранной страны.
   */
  async function postCountyId(id: number) {
    isPaymentMethodLoading.value = true;
    try {
      activeCountry.value = await api.payments.countriesActiveSelect({
        selectActiveCountryRequest: {
          countryId: id,
        },
      });
      paymentMethodsByGroups.value = activeCountry.value.methodGroups;
      paymentMethods.value = paymentMethodsByGroups.value.flatMap(
        (group) => group.methods,
      );
      selectedCountryName.value = activeCountry.value.name;
      selectedCountryFlag.value = activeCountry.value.flag;
      countryCurrency.value = activeCountry.value.currency;
      currencyRate.value = parseFloat(activeCountry.value.rate);
    } catch (e: any) {
      notify({
        type: "error",
        text: t("top_up_balance.notifications.countries_list_id_error"),
      });
    }
    isPaymentMethodLoading.value = false;
  }

  /**
   * Делает указанный платежный метод выбранным.
   *
   * @param method - платежный метод для выбора.
   */
  function selectPaymentMethod(method: PaymentMethod) {
    if (method.isActive) {
      paymentMethod.value = method;
    } else {
      paymentMethod.value = null;
    }
  }

  /**
   * Сбрасывает текущий выбранный платежный метод.
   */
  function dropSelectedPaymentMethod() {
    paymentMethod.value = null;
  }

  /**
   * Совершает запрос на поплнение баланса.
   */
  async function makePayment() {
    const invoiceRequest: Record<string, string | number> = {
      amount: topUpFormData.sum,
    };

    if (countryCurrency.value) {
      invoiceRequest.currency = countryCurrency.value;
    }

    if (topUpFormData.phone) {
      invoiceRequest.phone = topUpFormData.phone;
    }

    if (topUpFormData.email) {
      invoiceRequest.email = topUpFormData.email;
    }

    return api.payments.topUpBalanceCreate({
      paymentMethod: paymentMethod.value?.name ?? "",
      invoiceRequest,
    });
  }

  /**
   * Совершает платеж на пополнение баланса с помощью ваучера.
   */
  async function makeVoucherPayment() {
    await api.payments.topUpBalanceVoucher({
      gamelightVaucherRequest: {
        code: topUpFormData.voucherCode,
      },
    });
    notify({
      text: t("top_up_balance.notifications.code_string_success"),
    });
    useUserStore().getUser();
  }

  return {
    selectedPaymentsGroup,
    paymentGroupNames,
    paymentMethodsFiltered,
    paymentSelectItems,
    paymentMethodsByGroups,
    paymentMethod,
    paymentMethods,
    withdrawalGame,
    availableCurrencies,
    currencyRate,
    topUpFormData,
    countryCurrency,
    allCountries,
    selectedCountryName,
    selectedCountryFlag,
    activeCountry,
    selectedCurrency,
    isPaymentMethodLoading,
    fundsLackBullcoins,
    changeMethodToBase,
    initTopupPage,
    getPaymentMethodsByActiveCountry,
    getAllCountries,
    postCountyId,
    filterPaymentMethodsByGroup,
    addVoucherToMethodsList,
    selectCountry,
    selectPaymentGroup,
    selectPaymentMethod,
    dropSelectedPaymentMethod,
    makePayment,
    makeVoucherPayment,
  };
});
